﻿import { GetAntiForgeryToken, getPortalConfigLogin, LoginAAD, LoginNative, VerifyLoginMethod, LoginViaEmail, LoginCheckViaEmail, AcceptLoginViaEmail } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField } from '@fluentui/react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import React from 'react';

interface loginProps {
    ConfigLogin: IConfigLogin;
    Token:string


}


export const LoginLinkScreen: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [loaderMessage, setLoaderMessage] = useState("");
    const [currentStep, setCurrentStep] = useState("init");


    useEffect(() => {

        AcceptLoginViaEmail(props.Token).then((result) =>
        {
            if (result == "Ok") {
                setLoaderMessage("Please close this window and continue in the origin window");
            }
                
            else {
                setCurrentStep("email");
                setLoaderMessage("");
            }
                
        });
   

    }, []); 


    let logoHeight = "40px";
    if (props.ConfigLogin.config?.loginLogoHeight?.length) {
        logoHeight = props.ConfigLogin.config.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (props.ConfigLogin.config?.loginLogoTopMargin?.length) {
        logoMarginTop = props.ConfigLogin.config.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (props.ConfigLogin.config?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (props.ConfigLogin.config?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }


   



    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });



    return (

        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
            }}
        >
            <Card
                style={{
                    width: 380,
                    minHeight: 263,
                    padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                    backgroundColor: tokens.colorNeutralBackground1,
                }}
            >
                <CardHeader
                    image={getLogo()}
                />

                {loaderMessage && (
                    <div style={{ transform: "translateY(20%)" }}>
                      {/*  <Spinner size={"medium"} />*/}

                        <div
                            style={{
                                ...typographyStyles.subtitle1,
                                textAlign: "center",
                                marginTop: tokens.spacingVerticalS,
                            }}
                        >
                            {loaderMessage}
                        </div>
                    </div>
                )}
                {currentStep === "email" &&
                    <>
                        <div>
                            <div style={{ ...typographyStyles.subtitle1, marginBottom: tokens.spacingVerticalXS }}>
                                Link expired.
                            </div>
                            <div style={{ marginBottom: tokens.spacingVerticalXXXL }}>
                                This is a temporary link and can only be used once. Please click the button below to login in again.
                            </div>
                        </div>
                        <CardFooter>
                            <div style={{ width: "100%" }}>
                                <Button
                                appearance="primary"
                                style={{ width: "100%" }}
                                onClick={async () => {

                                    window.location.href="/user";

                                }}
                                >
                                    Login
                                </Button>

                                
                            </div>
                        </CardFooter>
                    </>
                }


                {/*<CardFooter>*/}
                {/*    <div style={{ width: "100%" }}>*/}
                {/*        <Button*/}
                {/*            appearance="primary"*/}
                {/*            style={{ width: "100%" }}*/}
                {/*            onClick={() => {*/}
                {/*                window.close();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*           Close this window.*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</CardFooter>*/}
            </Card>
        </div>

    );
}

