﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    Title2,
    TabValue,
    SelectTabEvent,
    SelectTabData
} from "@fluentui/react-components";
import { AddRegular, DeleteRegular, Dismiss24Regular, PersonAccountsRegular ,  DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { ICacheUserWithGroups, IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IDataSet, IDataSetColumn, PortalGroupSlim } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { AddDataSet, DeleteDataSet, GetAllDataSet, OpenDataSet, UpdateDataSet, UpdateDataSetAccess, getShareUsers } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { LookUpTableGrid } from "./lookUpTableGrid";
import { AccessControl } from "../shared/AccessControl";

var _allUsers: IUserShare[] = [];


export const LookUpTables: React.FC = () => {

    const userObj: IUserProps = usePersistentStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;
   

    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
   

    const [dataSets, setDataSets] = React.useState<IDataSet[]>([]);
    const [currentdataSet, SetCurrentDataSet] = React.useState<IDataSet>(null);
    const [hideDialog,setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit,  setIsOpenEdit] = React.useState(false);
    const [isPopupVisible, setIsPopUpVisible] = React.useState(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [NewName, setNewName] = React.useState<string>("");
    const [newEditor, setNewEditor] = React.useState<boolean>(false);
    const containerRef = React.useRef(null);
    const [items, setItems] = React.useState<Item[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dataSetid, setDataSetid] = React.useState<string>("");
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const inputColumnRef = React.useRef<HTMLInputElement>(null);
    const [accessIsOpen, setAccessIsOpen] = React.useState(false);



    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }
    type EnabledData = {
        label: string;
        enabled: boolean;
    }

    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabledDataSet: EnabledData;
    };


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",
            
            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";

                
                
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width:"500px"} }>{item.name.label}</div>
                        
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return   a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                <TableCellLayout style={{ width: "200px" }} >
                        {item.lastUpdated.label}
                </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "enabledDataSet",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "Dataset enabled";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.enabledDataSet.enabled?"Yes":"No"}
                    </TableCellLayout>
                );
            },
        }),

    ]

   
    React.useEffect(() => {
        if (isOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpenRename && inputColumnRef.current) {
            inputColumnRef.current.focus();
           /* inputColumnRef.current.select();*/
        }
    }, [isOpenRename]);

    function refreshDataSets(force:boolean) {

        if (isOpenEdit && !force)
            return;

        getAuthObject().then((authResult) => {
            GetAllDataSet(authResult).then((datasets) => {

                var tmpitems: Item[] = [];

                for (var i = 0; i < datasets.length; i++) {

                    var userEmail = "";
                    var formattedDate = "";

                    if (datasets[i].updated != undefined) {
                        let user = _allUsers.find(x => x.id === datasets[i].dataUpdatedBy) 
                        userEmail =  (user === undefined ? "n/a" : user.email);
                    }
                
                    try {
                        formattedDate =  new Date(datasets[i].dataUpdated).toLocaleString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute : '2-digit'

                        });

                    } catch (e) {
                        formattedDate = e.toString();

                    }
                  
                    var tmp: Item = {
                        name: { label: datasets[i].name, id: datasets[i].id },
                        enabledDataSet: { label: datasets[i].enabled ? "Yes" : "No", enabled: datasets[i].enabled },
                        lastUpdateBy: { label: userEmail},
                        lastUpdated: { label: formattedDate, timestamp: new Date(datasets[i].dataUpdated).getTime() }
                    }

                    tmpitems.push(tmp);
                }
                setItems(tmpitems);
                setDataSets(datasets);
            });

        });
    }
    const refreshusers = () => {

        return new Promise(resolve => {

            if (window.MyshareUsers != undefined) {

                var portalusers = window.MyshareUsers;
                var currentUser = JSON.parse(sessionStorage.getItem("user"));

                if (currentUser !== null && currentUser !== undefined) {
                    var u: IUserShare = {
                        email: currentUser.portalUser.Description.email,
                        id: currentUser.portalUser.id,
                        name: currentUser.portalUser.Description.name
                    };
                    portalusers.push(u);
                }
                _allUsers = portalusers;
                resolve("Ok");

            }
            else {

                getAuthObject().then((authResult) => {
                    getShareUsers(authResult).then((usersResult) => {

                        let pl: IUserShare[] = [];
                        pl = usersResult;
                        //_allUsers = pl;
                        window.MyshareUsers = pl;

                        var portalusers = pl;
                        var currentUser = JSON.parse(sessionStorage.getItem("user"));

                        if (currentUser !== null && currentUser !== undefined) {
                            var u: IUserShare = {
                                email: currentUser.portalUser.Description.email,
                                id: currentUser.portalUser.id,
                                name: currentUser.portalUser.Description.name
                            };
                            portalusers.push(u);
                        }
                        _allUsers = portalusers;
                        resolve("Ok");
                    });

                });
            }
        })
    }

    React.useEffect(() => {
        refreshusers().then(() => {
            refreshDataSets(false);    

        });
        
        
      
       
     
      
    }, []);

    const DeleteSelectedDataSets = () => {
        return getAuthObject().then((authResult) => {
            var dataSetIdArray = Array.from(selectedRows);
            var deletePromises = dataSetIdArray.map(dataSetId => DeleteDataSet(authResult, dataSetId.toString()));
            return Promise.all(deletePromises);
        });
    }

    //const DeleteSelectedDataSets = () => {
    //    getAuthObject().then(async (authResult) => {
    //        var dataSetIdArray = Array.from(selectedRows);
    //        for (var i = 0; i < dataSetIdArray.length; i++)
    //        {
    //            await DeleteDataSet(authResult, dataSetIdArray[i].toString());
    //        }
    //    });
    //}
    const AddNewDataSet = (name: string) => {
        getAuthObject().then((authResult) => {
            AddDataSet(authResult, name).then((dataset) => {
                refreshDataSets(false);
                SetCurrentDataSet(dataset);
               
                setIsOpen(false);
                setIsPopUpVisible(true);
                //SetAddColumnOpen(true);
            });


        });
    }
    

    const editDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            UpdateDataSet(authResult, dataSet).then((datasetResult) => {

                refreshDataSets(false);
                setIsOpenRename(false);
            });

        });
      

    }
    const OpenSingleDataSet = (datasetid) => {
        getAuthObject().then((authResult) => {
            OpenDataSet(authResult, datasetid).then((dataset) => {
                SetCurrentDataSet(dataset);
            });
        });
    }
  
    function onRenderFooterContent() {
        return (
            <div>
                <Button disabled={NewName.length < 1 || dataSets.findIndex(x => x.name === NewName) > -1} onClick={() => AddNewDataSet(NewName)} style={{ marginRight: "16px" }} >Add</Button>
                <Button onClick={() => { setIsOpen(false); }}>Cancel</Button>
            </div>
        )
    }

    function onRenderFooterContentEdit() {
        return (
            <div>
                <Button onClick={() => {
                    var s = currentdataSet
                    s.name = NewName
                    editDataSet(s)
                }} disabled={NewName.length < 1 || dataSets.findIndex(x => x.name === NewName) > -1} style={{ marginRight: "16px" }}>Save</Button>
                <Button  onClick={() => { setIsOpenEdit(false) }} >Cancel</Button>
            </div>
        )
    }

    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    function closeGrid(closeit: boolean) {
        if (closeit)
            setIsOpenEdit(false);
    }

    function forceRefresh() {
        refreshDataSets(true);
    }

   
    function updateAccess(usersToAdd: ICacheUserWithGroups[], usersToRemove: ICacheUserWithGroups[], groupsToAdd: PortalGroupSlim[], groupsToRemove: PortalGroupSlim[]) {

        async function _update() {

            var authObj = await getAuthObject();
            var dataSet = await UpdateDataSetAccess(authObj, currentdataSet.id, usersToAdd.map(user => user.id), usersToRemove.map(user => user.id), groupsToAdd.map(grp=>grp.id) ,groupsToRemove.map(grp=>grp.id))
            let updatedDataSets = dataSets.map(ds => ds.id === dataSet.id ? dataSet : ds);
            setDataSets(updatedDataSets);
            setAccessIsOpen(false);
        }

        _update();

    }

    function closeAccess() {
        setAccessIsOpen(false);
    }


    return (
        <div>
            <div
                style={{
                        marginTop: tokens.spacingVerticalNone,
                        marginBottom: tokens.spacingVerticalXL,
                        marginLeft: "20px",
                        maxWidth: "100%"
                       
                    }}
            >
            <Title2>Lookup tables</Title2>
            </div>
           

            {isOpenEdit && <div>  <LookUpTableGrid closeGrid={closeGrid} dataSet={currentdataSet} refresh={forceRefresh}  ></LookUpTableGrid></div>}


            {!open && 
                
                 <div
                style={{
                   
                    backgroundColor: tokens.colorNeutralBackground1,
                    color: tokens.colorNeutralForeground1,
                    borderTopLeftRadius: tokens.borderRadiusXLarge,
                    borderTopRightRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                    marginRight: tokens.spacingHorizontalM,
                                    
                width: "80dvw"


                }}>
                <Toolbar aria-label="Vertical Button" >
                <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {
                        setNewName("");
                    setIsOpen(true);

                } }>New
                    
                </ToolbarButton>
                <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={() => {
                   
                    var dataSetIdArray = Array.from(selectedRows);
                    var dataSetId = dataSetIdArray[0];
                    var dataset = dataSets.find(x => x.id === dataSetId.toString());
                    SetCurrentDataSet(dataset);
                    setIsOpenEdit(true);


                }}>Edit
                </ToolbarButton>
                <ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {
                  
                    var dataSetIdArray = Array.from(selectedRows);
                    var dataSetId = dataSetIdArray[0];
                    var dataset = dataSets.find(x => x.id === dataSetId.toString());
                    SetCurrentDataSet(dataset);
                    setNewName(dataset.name);
                    setIsOpenRename(true);

                } } >Rename
                </ToolbarButton>


                <ToolbarButton icon={<DeleteRegular></DeleteRegular>} disabled={selectedRows.size === 0} onClick={() => {
                    setIsOpenDelete(true);

                } }>
                    Delete
                    </ToolbarButton>

                    {userObj.permissions.findIndex(x => x == "Access management for data manager") > -1 &&
                            <ToolbarButton icon={<PersonAccountsRegular />} disabled={selectedRows.size !== 1} onClick={() => {

                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];
                            var dataset = dataSets.find(x => x.id === dataSetId.toString());
                            SetCurrentDataSet(dataset);
                            setAccessIsOpen(true);

                        }}>
                            Access
                        </ToolbarButton>}



                </Toolbar></div>}
                {!open &&
 <div
                style={{
                    overflowX: "hidden",

                    backgroundColor: tokens.colorNeutralBackground1,
                    color: tokens.colorNeutralForeground1,
                    
                    marginLeft: tokens.spacingHorizontalM,
                    marginRight: tokens.spacingHorizontalM,

                height: "69dvh",
                width: "80dvw"


                }}>


                    <DataGrid
                items={items}
                columns={columns}
                sortable
                selectionMode="multiselect"

                getRowId={(item) => item.name.id}
                focusMode="composite"
                size="medium"
                selectedItems={selectedRows}
                onSelectionChange={onSelectionChange}
                style={{ overflow: "auto" } }

            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select all rows" },
                        }}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}

                      


                    </DataGridRow>
                   
                </DataGridHeader>
                <DataGridBody<Item>>
                    {({ item, rowId }) => (
                        <DataGridRow<Item>
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select row" },
                            }}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid></div>
            }


            <Dialog open={isOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            Add new dataset
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                    <Input autoFocus={isOpen} defaultValue={NewName} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>

                        </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setIsOpen(false);

                                }} >Close</Button>

                             
                            <Button appearance="primary" disabled={NewName.length===0 }
                                onClick={() => {
                                    AddNewDataSet(NewName);
                                    setNewName("");
                                    setIsOpen(false);
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenRename}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenRename(false);
                                    }}
                                />
                            }
                        >
                            Rename dataset
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                    <Input defaultValue={NewName} autoFocus={isOpenRename} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>

                        </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setIsOpenRename(false);

                                }} >Close</Button>



                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    currentdataSet.name = NewName;
                                    editDataSet(currentdataSet);
                                    
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenDelete}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenDelete(false);
                                    }}
                                />
                            }
                        >
                            Delete?
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                               
                                Please confirm to delete {selectedRows.size} {selectedRows.size === 1 ? "dataset" : "datasets"}, when deleted the datasets can not be restored.
                            </div>

                        </DialogContent>
                            <DialogActions>

                               
                                    <Button onClick={() => {
                                        setIsOpenDelete(false);

                                    }} >Close</Button>
                                <Button 
                                    appearance="primary"
                                onClick={() => {
                                    DeleteSelectedDataSets().then(() => {
                                    refreshDataSets(false);
                                        setIsOpenDelete(false);
                                        setSelectedRows(new Set<TableRowId>([]));

                                    })
                                    
                                }}
                            >
                                Delete {selectedRows.size} {selectedRows.size===1?"dataset": "datasets"}
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
                {accessIsOpen && <AccessControl close={closeAccess} users={currentdataSet.usersWithAccess} groups={currentdataSet.groupsWithAccess} update={updateAccess}  ></AccessControl>}
           






         
            <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />
            </div>
      
      


        //<div style={{ backgroundColor: "#ffffff", width: "100vw", height: "100vh" }} ref={containerRef}>

        //    <div style={showMessageBar ? {} : { display: "hidden" }} className={classNamesMessageBar.MessageBarContainer}>
        //        {showMessageBar &&
        //            <MessageBar isMultiline={false} messageBarType={MessageBarType.success} dismissButtonAriaLabel="Close" onDismiss={() => { removeMsgBar() }}  >Record is created
        //            </MessageBar>
        //        }
        //    </div>


        //<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ root: { marginTop: "50px", marginLeft: "244px" } }}>
        //    {!isPopupVisible && <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        //        <div>
        //            <CommandBar

        //                items={commandItems}
        //                ariaLabel="Use left and right arrow keys to navigate between commands"
        //            />

        //        </div>
        //    </Sticky>}

        //    <div className={[classNames.columnPadding, classNames.defaultBackGround].join(' ')}>
        //        <div style={{ width: "1100px" }} >
        //            <MarqueeSelection selection={_selectCon}>
        //                <DetailsList
        //                    items={dataSets}
        //                    compact={false}

        //                    columns={_columns}
        //                    selectionMode={SelectionMode.single}
        //                    setKey="key"
        //                    selection={_selectCon}
        //                    selectionPreservedOnEmptyClick={true}
        //                    enterModalSelectionOnTouch={true}
        //                    ariaLabelForSelectionColumn="Toggle selection"
        //                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        //                    checkButtonAriaLabel="Row checkbox"

        //                />
        //            </MarqueeSelection>
        //        </div>
        //    </div>
        //    <div style={{ padding: '5px', width: '200px' }}>
        //    </div>
        //</ScrollablePane>
        //</div>
    )


}


