﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    Title2,
    TabValue,
    SelectTabEvent,
    SelectTabData,
    Checkbox,
    Switch,
    Toast,
    ToastTitle,
    useToastController,
    useId,
    Toaster,
    ToastTrigger,
    Link,
    ToastBody,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions
} from "@fluentui/react-components";
import { AddRegular, DeleteRegular, Dismiss24Regular, PersonAccountsRegular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { ICacheUserWithGroups, IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IApiHook, IApiService, PortalGroupSlim } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { UpdateApiHookAccess } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { LookUpTableGrid } from "./lookUpTableGrid";
import { AccessControl } from "../shared/AccessControl";
import { DeleteApiHooks, GetApiHooks, SaveApiHook, ValidateApiHook } from "../../apiFunctions/baseFunctions/adminFunctions";
import { GetSystemWebhooks } from "../../apiFunctions/questionnairefunctions";
import AceEditor from 'react-ace';
import ace from 'ace-builds/src-noconflict/ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/ext-inline_autocomplete';



var _allUsers: IUserShare[] = [];


export const ApiHookTables: React.FC = () => {

    const userObj: IUserProps = usePersistentStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;


    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();


    const [apiHooks, setApiHooks] = React.useState<IApiHook[]>([]);
    const [currentApiHook, SetCurrentApiHook] = React.useState<IApiHook>(null);
    const [hideDialog, setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isPopupVisible, setIsPopUpVisible] = React.useState(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [NewName, setNewName] = React.useState<string>("");
    const [newEditor, setNewEditor] = React.useState<boolean>(false);
    const containerRef = React.useRef(null);
    const [items, setItems] = React.useState<Item[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dataSetid, setDataSetid] = React.useState<string>("");
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const inputColumnRef = React.useRef<HTMLInputElement>(null);
    const [accessIsOpen, setAccessIsOpen] = React.useState(false);
    const [systemWebhooks, setsystemWebhooks] = React.useState<IApiService[]>([]);
    const [tempApiDefinition, setTempApiDefinition] = React.useState<string>("");
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const [validationError, setValidationError] = React.useState("");
    const [horizontal, setHorizontal] = React.useState(200);
    const [vertical, setVertical] = React.useState(16);
    const [isValidated, setIsValidated] = React.useState(false);
    const [beforeEdit, setBeforeEdit] = React.useState<IApiHook>(null);

    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }
    type EnabledData = {
        label: string;
        enabled: boolean;
    }

    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabledDataSet: EnabledData;
        systemHook: boolean;
    };


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",

            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";



            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width: "500px" }}>{item.name.label}</div>

                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout style={{ width: "200px" }} >
                        {item.lastUpdated.label.indexOf("2000")>-1 ? "" : item.lastUpdated.label}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "enabledDataSet",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "API enabled";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.enabledDataSet.enabled ? "Yes" : "No"}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "systemHook",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "System hook";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.systemHook ? "Yes" : "No"}
                    </TableCellLayout>
                );
            },
        }),

    ]


    React.useEffect(() => {
        if (isOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpenRename && inputColumnRef.current) {
            inputColumnRef.current.focus();
            /* inputColumnRef.current.select();*/
        }
    }, [isOpenRename]);

    React.useEffect(() => { }, [currentApiHook]);

    function refreshApihooks(force: boolean) {

        if (isOpenEdit && !force)
            return;

        getAuthObject().then((authResult) => {

            GetApiHooks(authResult).then((datasets) => {

                var tmpitems: Item[] = [];

               

                for (var i = 0; i < datasets.length; i++) {

                    
                    var formattedDate = "";


                 

                        try {
                            formattedDate = new Date(datasets[i].Updated).toLocaleString('en-GB', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'

                            });

                        } catch (e) {
                            formattedDate = e.toString();

                        }
                 
                    var tmp: Item = {
                        name: { label: datasets[i].name, id: datasets[i].id },
                        enabledDataSet: { label: datasets[i].enabled ? "Yes" : "No", enabled: datasets[i].enabled },
                        lastUpdateBy: { label: datasets[i].UpdatedBy },
                        lastUpdated: { label: formattedDate, timestamp: new Date(datasets[i].Updated).getTime() },
                        systemHook: datasets[i].issystem
                    }

                    tmpitems.push(tmp);
                }
                setItems(tmpitems);
                setApiHooks(datasets);
               

                GetSystemWebhooks(authResult).then((result) => {

                    //filter out the system webhooks if the id exists in the apiHooks
                   
                    var filtered = result.filter(x => datasets.findIndex(y => y.id === x.id) === -1);
                    
                    setsystemWebhooks(filtered);
                });

            });
            

        });
    }
   

    React.useEffect(() => {
   
            refreshApihooks(false);

   

    }, []);

    const DeleteSelectedApiHooks = async () => {
        var authResult = await getAuthObject();

        var dataSetIdArray = Array.from(selectedRows);
        var deleteRows: IApiHook[] = [];

        //find all apihooks matching datasetIdArray and add them to deleteRows
        for (var i = 0; i < dataSetIdArray.length; i++)
        {
            deleteRows.push(apiHooks.find(x => x.id == dataSetIdArray[i]));
        }
        await DeleteApiHooks(authResult, deleteRows);

    }

    //const DeleteSelectedDataSets = () => {
    //    getAuthObject().then(async (authResult) => {
    //        var dataSetIdArray = Array.from(selectedRows);
    //        for (var i = 0; i < dataSetIdArray.length; i++)
    //        {
    //            await DeleteDataSet(authResult, dataSetIdArray[i].toString());
    //        }
    //    });
    //}
    const UpdateApiHook = (hook:IApiHook) => {
        getAuthObject().then((authResult) => {
            if (hook.issystem && !hook.id ) {
                hook.id = hook.apidefinition.id;
                hook.apidefinition = null;
               
            }
            else if (hook.id===null) {
                hook.id = "";
            }
            SaveApiHook(authResult, hook).then((dataset) => {
                SetCurrentApiHook(null);
                refreshApihooks(false);
                setIsOpen(false);
            });
        });
    }


   

    const notifyError = (header: string, body: string) => {
        const bodyLines = body.split('#').map((line, index) => <div key={index}>{line}</div>);

        dispatchToast(
            <Toast style={{width:"400px"} } >
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }

                >{header}</ToastTitle>
                <ToastBody>
                    {bodyLines}
                </ToastBody>
            </Toast>,
            { position: "top-end", intent: "warning", timeout: 50000 }
        );
    }


    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    function closeGrid(closeit: boolean) {
        if (closeit)
            setIsOpenEdit(false);
    }

    function forceRefresh() {
        refreshApihooks(true);
    }


    function updateAccess(usersToAdd: ICacheUserWithGroups[], usersToRemove: ICacheUserWithGroups[], groupsToAdd: PortalGroupSlim[], groupsToRemove: PortalGroupSlim[]) {

        async function _update() {

            var authObj = await getAuthObject();
            var dataSet = await UpdateApiHookAccess(authObj, currentApiHook.id, usersToAdd.map(user => user.id), usersToRemove.map(user => user.id), groupsToAdd.map(grp => grp.id), groupsToRemove.map(grp => grp.id))

            var hook = apiHooks.find(x => x.id == dataSet.id);
            hook.GroupsWithAccess = dataSet.GroupsWithAccess;
            hook.UsersWithAccess = dataSet.UsersWithAccess;

            //setDataSets(updatedDataSets);
            setAccessIsOpen(false);
        }

        _update();

    }

    function closeAccess() {
        setAccessIsOpen(false);
    }

    const ValidateIfCustomHook = (apihookService: string) => new Promise<string>(async (resolve) => {
        var userObj = await getAuthObject();
        var result = await ValidateApiHook(userObj, apihookService);
        resolve(result);

    });

    function OkDisabled(): boolean 
    {
        if (currentApiHook === null)
            return true;    


        
        if (currentApiHook.issystem) {

            if (!currentApiHook.name)
                return true;
            if (JSON.stringify(currentApiHook) === JSON.stringify(beforeEdit))
                return true;
            if (currentApiHook.id)
                return false;
            if (!currentApiHook.apidefinition.id)
                return true;
            return false;
        }
        else {

            //custom hook
            if (!currentApiHook.name)
                return true;
            if (JSON.stringify(currentApiHook) !== JSON.stringify(beforeEdit))
                return false;
            if (JSON.stringify(currentApiHook.apidefinition,null,2) !== tempApiDefinition)
                return false;
            return true;
        }
    }

    return (
      <div>
            {/*{validationError &&*/}
            {/*    <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 50000 }}>*/}
            {/*        <MessageBar intent={"warning"}>*/}
            {/*            <MessageBarBody>*/}
            {/*                <MessageBarTitle>Validation Error</MessageBarTitle>*/}
            {/*                {validationError.split('#').map((line, index) => (*/}
            {/*                    <div key={index}>{line}</div>*/}
            {/*                ))}*/}
            {/*            </MessageBarBody>*/}
            {/*            <MessageBarActions*/}
            {/*                containerAction={*/}
            {/*                    <Button*/}
            {/*                        aria-label="dismiss"*/}
            {/*                        appearance="transparent"*/}
            {/*                        icon={<DismissRegular />}*/}
            {/*                        onClick={() => { setValidationError("") }}*/}
            {/*                    />*/}
            {/*                }*/}
            {/*            >*/}

            {/*            </MessageBarActions>*/}
            {/*        </MessageBar>*/}
            {/*    </div>*/}

            {/*}*/}

        <div>
          
            <div
                style={{
                    marginTop: tokens.spacingVerticalNone,
                    marginBottom: tokens.spacingVerticalXL,
                    marginLeft: "20px",
                    maxWidth: "100%"

                }}
            >
                <Title2>API Hooks</Title2>
            </div>



            {!open &&

                <div
                    style={{

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        width: "80dvw"


                    }}>
                    <Toolbar aria-label="Vertical Button" >
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {
                                var _newHook: IApiHook = {} as IApiHook;
                                _newHook.apidefinition = {} as IApiService;
                                _newHook.id = null;
                                _newHook.issystem = false;
                                setBeforeEdit(_newHook);
                                SetCurrentApiHook(_newHook);
                                setNewName("");
                                setIsOpen(true);

                        }}>New

                        </ToolbarButton>
                        <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];
                                var dataset = apiHooks.find(x => x.id === dataSetId.toString());
                            setBeforeEdit(dataset);
                            SetCurrentApiHook(dataset);
                            if (!dataset.issystem)
                                setTempApiDefinition(JSON.stringify(dataset.apidefinition, null, 2));
                            else
                                setTempApiDefinition("");

                            setIsOpen(true);


                        }}>Edit
                        </ToolbarButton>
                        {/*<ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {*/}

                        {/*    var dataSetIdArray = Array.from(selectedRows);*/}
                        {/*    var dataSetId = dataSetIdArray[0];*/}
                        {/*    var dataset = apiHooks.find(x => x.id === dataSetId.toString());*/}
                        {/*    SetCurrentApiHook(dataset);*/}
                        {/*    setNewName(dataset.name);*/}
                        {/*    setIsOpenRename(true);*/}

                        {/*}} >Rename*/}
                        {/*</ToolbarButton>*/}


                        <ToolbarButton icon={<DeleteRegular></DeleteRegular>}disabled={selectedRows.size === 0} onClick={() => {
                            setIsOpenDelete(true);

                        }}>
                            Delete
                        </ToolbarButton>

                        {userObj.permissions.findIndex(x => x == "Access management for data manager") > -1 &&
                                <ToolbarButton icon={<PersonAccountsRegular />} disabled={selectedRows.size !== 1} onClick={() => {
                                var dataSetIdArray = Array.from(selectedRows);
                                var dataSetId = dataSetIdArray[0];
                                var dataset = apiHooks.find(x => x.id === dataSetId.toString());
                                SetCurrentApiHook(dataset);
                                setAccessIsOpen(true);

                            }}>
                                Access
                            </ToolbarButton>}



                    </Toolbar></div>}
            {!open &&
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,

                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        height: "69dvh",
                        width: "80dvw"


                    }}>


                    <DataGrid
                        items={items}
                        columns={columns}
                        sortable
                        selectionMode="multiselect"

                        getRowId={(item) => item.name.id}
                        focusMode="composite"
                        size="medium"
                        selectedItems={selectedRows}
                        onSelectionChange={onSelectionChange}
                        style={{ overflow: "auto" }}

                    >
                        <DataGridHeader>
                            <DataGridRow
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select all rows" },
                                }}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}




                            </DataGridRow>

                        </DataGridHeader>
                        <DataGridBody<Item>>
                            {({ item, rowId }) => (
                                <DataGridRow<Item>
                                    key={rowId}
                                    selectionCell={{
                                        checkboxIndicator: { "aria-label": "Select row" },
                                    }}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid></div>
            }


            {currentApiHook !== null && isOpen && <Dialog open={isOpen}>
                <DialogSurface style={{ width:"60vw"} }  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            {currentApiHook.id === null ? "Add new API" : "Edit API"}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label style={{ paddingBottom: tokens.spacingVerticalS, paddingTop: tokens.spacingVerticalS }} >
                                    Name
                                </Label>
                                <Input autoFocus={isOpen} defaultValue={currentApiHook.id === null ? NewName : currentApiHook.name}
                                    onChange={(e, v) => {
                                        var _temp = { ...currentApiHook, name: e.currentTarget.value };
                                        SetCurrentApiHook(_temp);
                                    }} ref={inputColumnRef} />
                            </div>
                            {currentApiHook.id && <div style={{ display: "flex", flexDirection: "column" }}>

                                <Switch
                                    label="Enabled"
                                    style={{ paddingBottom: tokens.spacingVerticalS, paddingTop: tokens.spacingVerticalS }}
                                    defaultChecked={currentApiHook.enabled}
                                    onChange={(e, v) => {
                                        var _temp = { ...currentApiHook, enabled: v.checked };
                                        SetCurrentApiHook(_temp);
                                    } }
                                />

                                </div>
                            }
                            {currentApiHook.id && !currentApiHook.issystem && <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label style={{ paddingBottom: tokens.spacingVerticalS, paddingTop: tokens.spacingVerticalS }}>
                                    Api definition
                                </Label>
                                <div style={{ height: "50vh", width: "98%", border: "1px solid black" }}>
                                    <AceEditor
                                        style={{ height: "97%", width: "100%", margin: "8px 0px 0px 0px" }}
                                        highlightActiveLine={true}
                                        mode="json"
                                        theme="tomorrow"
                                        value={tempApiDefinition}
                                        onChange={(v, e) => {
                                            setTempApiDefinition(v);

                                            
                                        }}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }} 
                                    />
                                </div>


                                {/*<Switch*/}
                                {/*    label="Enabled"*/}
                                {/*    style={{ paddingBottom: tokens.spacingVerticalS, paddingTop: tokens.spacingVerticalS }}*/}
                                {/*    defaultChecked={currentApiHook.enabled}*/}
                                {/*    onChange={(e, v) => {*/}
                                {/*        var _temp = { ...currentApiHook, enabled: v.checked };*/}
                                {/*        SetCurrentApiHook(_temp);*/}
                                {/*    }}*/}
                                {/*/>*/}

                            </div>
                            }



                            {!currentApiHook.id && <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label style={{ paddingBottom: tokens.spacingVerticalS, paddingTop:tokens.spacingVerticalS }}>
                                    Select API type
                                </Label>
                                <Dropdown

                                    placeholder="Please select API type"
                                    defaultValue={currentApiHook.issystem ? "System API" : "Customer API"}

                                >

                                    {systemWebhooks.length > 0 && <Option key={0}
                                        onClick={(v) => {
                                            //set currentAPIHook to system API
                                            var _temp = { ...currentApiHook, issystem: true };
                                            SetCurrentApiHook(_temp);

                                        }}
                                    >

                                        System API
                                    </Option>
                                    }
                                    <Option key={1}
                                        onClick={(v) => {
                                            //set currentAPIHook to system API
                                            var _temp = { ...currentApiHook, issystem: false };
                                            SetCurrentApiHook(_temp);

                                        }}

                                    >


                                        Customer API
                                    </Option>


                                </Dropdown>


                                {currentApiHook.issystem && 
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      <Label style={{ paddingBottom: tokens.spacingVerticalS, paddingTop:tokens.spacingVerticalS }}>
                                    Select System API
                                </Label>

                                     <Dropdown

                                            placeholder="Please select system API"
                                            style={{ paddingBottom: tokens.spacingVerticalS, paddingTop: tokens.spacingVerticalS }}
                                    >


                                    {systemWebhooks.map((value, index) => {
                                            return <Option key={index}
                                                onClick={(v) => {
                                                    //set currentAPIHook to system API
                                                    var _temp = { ...currentApiHook };
                                                    _temp.apidefinition.id = value.id;  
                                                    SetCurrentApiHook(_temp);

                                                }}
                                            >
                                                {value.name}
                                            </Option>
                                        })}
                                </Dropdown>
                                </div>


                                



                                }


                            </div>




                            }




                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setTempApiDefinition("");
                                SetCurrentApiHook(null);
                                setIsOpen(false);

                            }} >Close</Button>


                                <Button appearance="primary" disabled={OkDisabled() }
                                onClick={async () => {

                                    setValidationError("");
                                    if (!currentApiHook.issystem && currentApiHook.id) {
                                        //validate 
                                        try {
                                            var result = await ValidateIfCustomHook(tempApiDefinition);

                                            if (result != "Ok")
                                            {
                                                setValidationError(result);
                                              
                                                notifyError("Validation error", result);
                                                return;
                                            }
                                            
                                            var _temp = { ...currentApiHook, apidefinition: JSON.parse(tempApiDefinition) };
                                            UpdateApiHook(_temp);
                                            setIsOpen(false);
                                            setTempApiDefinition("");
                                            SetCurrentApiHook(null);
                                            return;

                                        } catch (e)
                                        {
                                            
                                            notifyError("Validation error", e);
                                            setValidationError(e);
                                            return;
                                        }
                                    }  
                                    UpdateApiHook(currentApiHook);
                                    setIsOpen(false);
                                    setTempApiDefinition("");
                                    SetCurrentApiHook(null);
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            }
        
            <Dialog open={isOpenDelete}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenDelete(false);
                                    }}
                                />
                            }
                        >
                            Delete?
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                Please confirm to delete {selectedRows.size} {selectedRows.size === 1 ? "API hook" : "API hooks"}, when deleted the api can not be restored.
                            </div>

                        </DialogContent>
                        <DialogActions>


                            <Button onClick={() => {
                                setIsOpenDelete(false);

                            }} >Close</Button>
                            <Button
                                appearance="primary"
                                onClick={async ()  =>  {
                                    
                                    await DeleteSelectedApiHooks();
                                    refreshApihooks(false);
                                    setIsOpenDelete(false);
                                    setSelectedRows(new Set<TableRowId>([]));

                                    

                                }}
                            >
                                Delete {selectedRows.size} {selectedRows.size === 1 ? "API hook" : "API hooks"}
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {accessIsOpen && <AccessControl close={closeAccess} users={currentApiHook.UsersWithAccess} groups={currentApiHook.GroupsWithAccess} update={updateAccess}  ></AccessControl>}
          







            <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />
                <Toaster toasterId={toasterId} offset={{ horizontal,vertical }} />
        </div>


    </div>
 

    )


}


